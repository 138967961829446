<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          label-position="right"
          label-width="120px"
        >
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base none-title">基本信息</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="车牌号"
                prop="steelCarSatelliteInfoVO.plateNumber"
              >
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.plateNumber"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="车牌颜色"
                prop="steelCarSatelliteInfoVO.plateColor"
              >
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.plateColor"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, idx) in $enums.PlateColor.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="车辆归属"
                prop="steelCarSatelliteInfoVO.carBlong"
              >
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.carBlong"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, idx) in $enums.CarAttribution.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="挂靠单位"
                prop="steelCarSatelliteInfoVO.carUnit"
              >
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.carUnit"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, idx) in companyList"
                    :key="idx"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="车辆类型"
                prop="steelCarSatelliteInfoVO.carSatelliteType"
              >
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.carSatelliteType"
                  placeholder="请选择"
                >
                  <el-option label="半挂牵引车" value="半挂牵引车"></el-option>
                  <el-option label="半挂车" value="半挂车"></el-option>
                  <el-option label="普通货车" value="普通货车"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="车辆状态"
                prop="steelCarSatelliteInfoVO.carStatus"
              >
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.carStatus"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, idx) in $enums.AddCarStatus.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="责任人">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.responsible"
                  placeholder="请输入"
                  maxlength="25"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="手机号码">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.contactPhone"
                  placeholder="请输入"
                  maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="年检有效期">
                <el-date-picker
                  v-model="form.steelCarSatelliteInfoVO.validity"
                  type="daterange"
                  unlink-panels
                  value-format="timestamp"
                  range-separator="/"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="设备号">
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.deviceId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, idx) in deviceIDList"
                    :key="idx"
                    :label="item.deviceName"
                    :value="item.deviceId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="所属车队">
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.groupId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, idx) in driverList"
                    :key="idx"
                    :label="item.teamName"
                    :value="item.groupId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="自重(吨)">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.carSelfWeight"
                  placeholder="请输入"
                  maxlength="18"
                  @blur="validityNumber"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="载重(吨)">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.calibrationTon"
                  placeholder="请输入"
                  maxlength="18"
                  @blur="validityNumber"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="品牌型号"
                prop="steelCarSatelliteInfoVO.carBrand"
              >
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.carBrand"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="车辆识别码">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.carIdentificationCode"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="发动机号码">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.engineNumber"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="配套托架">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.bracketNumber"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="备注">
                <el-input
                  v-model="form.steelCarSatelliteInfoVO.carRemark"
                  placeholder="请输入"
                  maxlength="64"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">油箱参数</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <!-- 油箱参数 -->
              <el-form-item
                label="油量监测"
                prop="steelCarSatelliteInfoVO.hasFuel"
              >
                <el-select
                  v-model="form.steelCarSatelliteInfoVO.hasFuel"
                  placeholder="请选择"
                  @change="oilMonitoringClick"
                >
                  <el-option
                    v-for="(item, idx) in $enums.oilMonitoring.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="油箱形状"
                v-if="stateShape === '启用'"
                prop="tankShapeData.shape"
              >
                <el-select
                  v-model="form.tankShapeData.shape"
                  placeholder="请选择"
                  @change="tankShapeClick"
                >
                  <el-option
                    v-for="(item, idx) in $enums.tankShape.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="长(cm)"
                v-if="stateShape === '启用' && tankShapeItem !== null"
                prop="tankShapeData.long"
              >
                <el-input
                  v-model="form.tankShapeData.long"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="宽(cm)"
                v-if="stateShape === '启用' && tankShapeItem === '长方形'"
                prop="tankShapeData.wide"
              >
                <el-input
                  v-model="form.tankShapeData.wide"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="高(cm)"
                v-if="stateShape === '启用' && tankShapeItem === '长方形'"
                prop="tankShapeData.height"
              >
                <el-input
                  v-model="form.tankShapeData.height"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="直径(cm)"
                v-if="
                  (stateShape === '启用' && tankShapeItem === '圆形') ||
                  tankShapeItem === 'D型'
                "
                prop="tankShapeData.diameter"
              >
                <el-input
                  v-model="form.tankShapeData.diameter"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="长直径(cm)"
                v-if="stateShape === '启用' && tankShapeItem === '椭圆形'"
                prop="tankShapeData.longDiameter"
              >
                <el-input
                  v-model="form.tankShapeData.longDiameter"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="短直径(cm)"
                v-if="stateShape === '启用' && tankShapeItem === '椭圆形'"
                prop="tankShapeData.shortDiameter"
              >
                <el-input
                  v-model="form.tankShapeData.shortDiameter"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="颈高(cm)"
                v-if="stateShape === '启用' && tankShapeItem === '椭圆形'"
                prop="tankShapeData.neckHeight"
              >
                <el-input
                  v-model="form.tankShapeData.neckHeight"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="油杆长(cm)"
                v-if="stateShape === '启用' && tankShapeItem !== null"
                prop="tankShapeData.rodLength"
              >
                <el-input
                  v-model="form.tankShapeData.rodLength"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="油箱厚度(cm)"
                v-if="stateShape === '启用' && tankShapeItem !== null"
                prop="tankShapeData.thicknessTank"
              >
                <el-input
                  v-model="form.tankShapeData.thicknessTank"
                  placeholder="请输入"
                  type="number"
                  maxlength="3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="最大标定值"
                v-if="stateShape === '启用' && tankShapeItem !== null"
                prop="tankShapeData.maxCalibration"
              >
                <el-input
                  v-model="form.tankShapeData.maxCalibration"
                  placeholder="请输入"
                  type="number"
                  maxlength="6"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item
                label="容积修正参数"
                v-if="
                  stateShape === '启用' &&
                  tankShapeItem !== null &&
                  tankShapeItem !== '椭圆形'
                "
                prop="tankShapeData.correctionParameter"
              >
                <el-input
                  v-model="form.tankShapeData.correctionParameter"
                  placeholder="请输入"
                  type="number"
                  minlength="5"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">保险信息</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="保险公司">
                <el-input
                  v-model="form.insuranceInfoVOIOV.insuranceCompany"
                  placeholder="请输入"
                  maxlength="64"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="保险类型">
                <el-input
                  v-model="form.insuranceInfoVOIOV.insuranceType"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="保险单号">
                <el-input
                  v-model="form.insuranceInfoVOIOV.insuranceNumber"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="保险有效期">
                <el-date-picker
                  v-model="form.insuranceInfoVOIOV.insuranceValidity"
                  type="daterange"
                  unlink-panels
                  value-format="timestamp"
                  range-separator="/"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="购买价格(元)">
                <el-input
                  v-model="form.insuranceInfoVOIOV.purchasePrice"
                  placeholder="请输入"
                  maxlength="18"
                  @blur="validityNumber"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="代理人">
                <el-input
                  v-model="form.insuranceInfoVOIOV.agent"
                  placeholder="请输入"
                  maxlength="25"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="手机号码">
                <el-input
                  v-model="form.insuranceInfoVOIOV.contactPhone"
                  placeholder="请输入"
                  maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="备注">
                <el-input
                  v-model="form.insuranceInfoVOIOV.insuranceRemark"
                  placeholder="请输入"
                  maxlength="64"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">行驶证信息</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="行驶证所有人">
                <el-input
                  v-model="form.drivingLicenseInfoVOIOV.owner"
                  placeholder="请输入"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="使用性质">
                <el-select
                  v-model="form.drivingLicenseInfoVOIOV.usage"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, idx) in $enums.UseNature.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="行驶证有效期">
                <el-date-picker
                  v-model="form.drivingLicenseInfoVOIOV.drivingValidity"
                  type="daterange"
                  unlink-panels
                  value-format="timestamp"
                  range-separator="/"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="备注">
                <el-input
                  v-model="form.drivingLicenseInfoVOIOV.driverLicenseRemark"
                  placeholder="请输入"
                  maxlength="64"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">运输证信息</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="运输证号">
                <el-input
                  v-model="form.transportLicenseInfoVOIOV.transportNumber"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="经营范围">
                <el-input
                  v-model="form.transportLicenseInfoVOIOV.businessScope"
                  placeholder="请输入"
                  maxlength="32"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="运输证有效期">
                <el-date-picker
                  v-model="form.transportLicenseInfoVOIOV.transportValidity"
                  type="daterange"
                  unlink-panels
                  value-format="timestamp"
                  range-separator="/"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="备注">
                <el-input
                  v-model="
                    form.transportLicenseInfoVOIOV.transportLicenseRemark
                  "
                  placeholder="请输入"
                  maxlength="64"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">图片信息</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="8">
              <el-form-item label="车辆图片">
                <ayl-img-upload
                  v-model="carImageKey"
                  :imgListLength="1"
                  :imgWidth="'300px'"
                  :imgHeight="'150px'"
                  :uploadDesc="'请上传车辆图片'"
                  :uploadIcon="'avatar-icon iconfont iconzhaopian'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="8">
              <!-- 行驶证-->
              <el-form-item label="行驶证图片">
                <ayl-img-upload
                  v-model="driverLicenseImageKeyList"
                  :imgListLength="1"
                  :imgWidth="'300px'"
                  :imgHeight="'150px'"
                  :uploadDesc="'请上传行驶证图片'"
                  :uploadIcon="'avatar-icon iconfont iconzhaopian'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="8">
              <!-- 运输证-->
              <el-form-item label="运输证图片">
                <ayl-img-upload
                  v-model="transportLicenseImageKeyList"
                  :imgListLength="1"
                  :imgWidth="'300px'"
                  :imgHeight="'150px'"
                  :uploadDesc="'请上传运输证图片'"
                  :uploadIcon="'avatar-icon iconfont iconzhaopian'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="tac" style="margin-top: 40px">
        <el-button type="primary" @click="submitForm()" :loading="submitLoading"
          >确 认</el-button
        >
        <el-button
          style="margin-left: 30px"
          @click="$router.push('/vehicle-management/car-management')"
          >返 回</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const validatePlateNumber = (rule, value, callback) => {
      if (
        !value ||
        !/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳]$/.test(
          value
        )
      ) {
        callback(new Error("请输入7位的车牌号码"));
      } else {
        callback();
      }
    };
    const validateContactPhone = (rule, value, callback) => {
      if (!value || !/^[1][0-9][0-9]{9}$/.test(value)) {
        callback(new Error("请输入11位手机号码"));
      } else {
        callback();
      }
    };
    const validateServiceDate = (rule, value, callback) => {
      if (
        !this.form.steelCarSatelliteInfoVO.annualInspectionStartTime &&
        !this.form.steelCarSatelliteInfoVO.annualInspectionEndTime
      )
        return callback();
      if (
        this.form.steelCarSatelliteInfoVO.annualInspectionEndTime <
        this.form.steelCarSatelliteInfoVO.annualInspectionStartTime
      ) {
        callback(new Error("结束日期应大于开始日期"));
      } else {
        callback();
      }
    };
    return {
      carImageKey: [], //车辆图片
      driverLicenseImageKeyList: [], //行驶证图片
      transportLicenseImageKeyList: [], //运输证图片

      stateShape: "未启用", //油箱状态
      tankShapeItem: null, //油箱形状
      driverList: [],
      deviceIDList: [],
      companyList: [], //挂靠单位
      nav: [
        { name: "车辆管理", path: "" },
        { name: "车辆管理", path: "/vehicle-management/car-management" },
        { name: "编辑", path: "" },
      ],
      submitLoading: false,
      valueImage: true,
      valueImageF: true,
      valueImageJ: true,
      valueImageX: true,
      form: {
        steelCarSatelliteInfoVO: {
          plateNumber: null, //车牌号
          plateColor: null, //车牌颜色
          carStatus: null, //车辆状态
          carBlong: null, //车辆归属
          carSatelliteType: null, //车辆类型
          annualInspectionStartTime: null, //年检开始时间
          annualInspectionEndTime: null, //年检结束时间
          validity: [],
          responsible: null, //责任人
          contactPhone: null, //手机号码
          carUnit: null, //所属公司
          carSelfWeight: null, //自重
          calibrationTon: null, //载重
          carBrand: null, //品牌型号
          carIdentificationCode: null, //车辆识别代码
          engineNumber: null, //发动机号码
          bracketNumber: null, //配套托架
          carImageUrl: "", //车辆照片，上传
          carImageKey: "", //本地显示
          isClear: true,
          // idCardImgZ: {                 //车辆照片
          //     imageKey: '',
          //     imageUrl: '',
          //     isClear: true,
          // },
          carRemark: null, //备注
          deviceId: null, //设备号
          deviceName: null,
          groupId: null, //所属车队ID
          teamName: null,
          hasFuel: "未启用", //油量监测
        },
        //油箱参数
        tankShapeData: {
          shape: null, //油箱形状
          long: null, //长
          wide: null, //宽
          height: null, //高
          diameter: null, //直径
          longDiameter: null, //长直径
          shortDiameter: null, //短直径
          neckHeight: null, //颈高
          rodLength: null, //油杆长
          thicknessTank: null, //油箱厚度
          maxCalibration: null, //最大标定值
          correctionParameter: null, //容积修正参数
        },

        insuranceInfoVOIOV: {
          insuranceCompany: null, //保险公司
          insuranceType: null, //保险类型
          insuranceNumber: null, //保险单号
          insuranceValidity: [],
          startTime: null, //开始时间
          endTime: null, //结束时间
          purchasePrice: null, //价格
          contactPhone: null, //联系电话
          agent: null, //代理人
          insuranceRemark: null, //备注
        },

        drivingLicenseInfoVOIOV: {
          owner: null, //行驶证持有人
          usage: null, //用途
          drivingValidity: [],
          startTime: null, //开始时间
          endTime: null, //结束时间
          //licenseNumber: null,        //行驶证号
          driverLicenseImageKeyList: "", //行驶证图片，上传
          driverLicenseImageUrlList: "", //本地显示
          isClear: true,
          // idCardImgF: {
          //     imageKey: '',
          //     imageUrl: '',
          //     isClear: true,
          // },
          driverLicenseRemark: null, //备注
        },

        transportLicenseInfoVOIOV: {
          transportNumber: null, //运输证号
          businessScope: null, //经营范围
          transportValidity: [],
          startTime: null, //开始时间
          endTime: null, //结束时间
          transportLicenseImageKeyList: "", //运输证图片
          transportLicenseImageUrlList: "",
          isClear: true,
          // idCardImgJ: {
          //     imageKey: '',
          //     imageUrl: '',
          //     isClear: true,
          // },
          transportLicenseRemark: null, //备注
        },
        deviceId: null,
        driverName: null,
      },
      rules: {
        "steelCarSatelliteInfoVO.plateNumber": {
          required: true,
          message: "请输入车牌号",
          trigger: "blur",
        },
        "steelCarSatelliteInfoVO.plateColor": {
          required: true,
          message: "请选择车牌颜色",
          trigger: "change",
        },
        "steelCarSatelliteInfoVO.carBlong": {
          required: true,
          message: "请选择车辆归属",
          trigger: "change",
        },
        "steelCarSatelliteInfoVO.carUnit": {
          required: true,
          message: "请选择挂靠单位",
          trigger: "change",
        },
        "steelCarSatelliteInfoVO.carSatelliteType": {
          required: true,
          message: "请选择车辆类型",
          trigger: "change",
        },
        "steelCarSatelliteInfoVO.carStatus": {
          required: true,
          message: "请选择车辆状态",
          trigger: "change",
        },
        "steelCarSatelliteInfoVO.responsible": [
          {
            min: 2,
            max: 25,
            required: true,
            message: "请输入责任人",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        "steelCarSatelliteInfoVO.contactPhone": {
          required: true,
          trigger: "blur",
          validator: validateContactPhone,
        },
        "steelCarSatelliteInfoVO.validity": [
          { required: true, message: "请选择年检有效期", trigger: "change" },
          { validator: validateServiceDate, trigger: "blur" },
        ],
        "steelCarSatelliteInfoVO.hasFuel": {
          required: true,
          message: "请选择油量监测",
          trigger: "change",
        },
        "steelCarSatelliteInfoVO.carBrand": {
          required: true,
          message: "请正确品牌型号",
          trigger: "blur",
        },
        "tankShapeData.shape": {
          required: true,
          message: "请选择油箱形状",
          trigger: "change",
        },
        "tankShapeData.long": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.wide": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.height": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.diameter": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.longDiameter": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.shortDiameter": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.neckHeight": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.rodLength": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.thicknessTank": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isOneDecimal },
        ],
        "tankShapeData.maxCalibration": [
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isInteger },
        ],
        "tankShapeData.correctionParameter": [
          {
            min: 5,
            max: 10,
            required: true,
            message: "请精确到小数点后四位",
            trigger: "blur",
          },
          { required: true, message: "请正确输入参数", trigger: "blur" },
          { validator: this.$validator.isForsDecimal },
        ],
      },
    };
  },
  methods: {
    /**
     * 规范数据输入框
     */
    validityNumber(val) {
      //console.log(val)
      let tempVal = val.target.value;
      const reg = /^[.0-9]*$/;
      if (!reg.test(tempVal) && tempVal != "") {
        this.$message.error("请输入规范数字");
      }
    },
    // 提交
    async submitInternal() {
      // eslint-disable-next-line no-unused-vars
      await this.$api.updateCarSatelliteInfoByPlateNumber({
        steelCarDetailInfoVO: {
          steelCarSatelliteInfoVO: {
            plateNumber: this.form.steelCarSatelliteInfoVO.plateNumber,
            plateColor: this.form.steelCarSatelliteInfoVO.plateColor,
            carStatus: this.form.steelCarSatelliteInfoVO.carStatus,
            carBlong: this.form.steelCarSatelliteInfoVO.carBlong,
            carSatelliteType: this.form.steelCarSatelliteInfoVO
              .carSatelliteType,
            annualInspectionStartTime:
              this.form.steelCarSatelliteInfoVO.validity == null
                ? null
                : this.form.steelCarSatelliteInfoVO.validity[0],
            annualInspectionEndTime:
              this.form.steelCarSatelliteInfoVO.validity == null
                ? null
                : this.form.steelCarSatelliteInfoVO.validity[1],
            responsible: this.form.steelCarSatelliteInfoVO.responsible,
            contactPhone: this.form.steelCarSatelliteInfoVO.contactPhone,
            carUnit: this.form.steelCarSatelliteInfoVO.carUnit,
            carSelfWeight: this.form.steelCarSatelliteInfoVO.carSelfWeight,
            calibrationTon: this.form.steelCarSatelliteInfoVO.calibrationTon,
            carBrand: this.form.steelCarSatelliteInfoVO.carBrand,
            carIdentificationCode: this.form.steelCarSatelliteInfoVO
              .carIdentificationCode,
            engineNumber: this.form.steelCarSatelliteInfoVO.engineNumber,
            bracketNumber: this.form.steelCarSatelliteInfoVO.bracketNumber,
            carImageUrl:
              this.carImageKey.length > 0 ? this.carImageKey[0] : null,
            carRemark: this.form.steelCarSatelliteInfoVO.carRemark,
            deviceId: this.form.steelCarSatelliteInfoVO.deviceId,
            groupId: this.form.steelCarSatelliteInfoVO.groupId,
            hasFuel: this.form.steelCarSatelliteInfoVO.hasFuel,
          },
          carFuelInfoVO: {
            id: this.form.tankShapeData.id,
            fuelTankType: this.form.tankShapeData.shape,
            length: this.form.tankShapeData.long,
            width: this.form.tankShapeData.wide,
            height: this.form.tankShapeData.height,
            diameter: this.form.tankShapeData.diameter,
            longDiameter: this.form.tankShapeData.longDiameter,
            shortDiameter: this.form.tankShapeData.shortDiameter,
            neckHeight: this.form.tankShapeData.neckHeight,
            fuelRodLength: this.form.tankShapeData.rodLength,
            thickness: this.form.tankShapeData.thicknessTank,
            maxCalibration: parseInt(this.form.tankShapeData.maxCalibration),
            volumeRevisionFactor: this.form.tankShapeData.correctionParameter,
            
          },
          insuranceInfoVOIOV: {
            insuranceId: this.form.insuranceInfoVOIOV.insuranceId,
            insuranceCompany: this.form.insuranceInfoVOIOV.insuranceCompany, //保险公司
            insuranceType: this.form.insuranceInfoVOIOV.insuranceType, //保险类型
            insuranceNumber: this.form.insuranceInfoVOIOV.insuranceNumber, //保险单号
            //insuranceValidity: [],
            startTime:
              this.form.insuranceInfoVOIOV.insuranceValidity == null
                ? null
                : this.form.insuranceInfoVOIOV.insuranceValidity[0], //开始时间
            endTime:
              this.form.insuranceInfoVOIOV.insuranceValidity == null
                ? null
                : this.form.insuranceInfoVOIOV.insuranceValidity[1], //结束时间
            purchasePrice: this.form.insuranceInfoVOIOV.purchasePrice, //价格
            contactPhone: this.form.insuranceInfoVOIOV.contactPhone, //联系电话
            agent: this.form.insuranceInfoVOIOV.agent, //代理人
            insuranceRemark: this.form.insuranceInfoVOIOV.insuranceRemark, //备注
            
          },
          drivingLicenseInfoVOIOV: {
            drivingLicenseId: this.form.drivingLicenseInfoVOIOV.drivingLicenseId,
            owner: this.form.drivingLicenseInfoVOIOV.owner,
            usage: this.form.drivingLicenseInfoVOIOV.usage,
            startTime:
              this.form.drivingLicenseInfoVOIOV.drivingValidity == null
                ? null
                : this.form.drivingLicenseInfoVOIOV.drivingValidity[0],
            endTime:
              this.form.drivingLicenseInfoVOIOV.drivingValidity == null
                ? null
                : this.form.drivingLicenseInfoVOIOV.drivingValidity[1],
            //licenseNumber: this.form.drivingLicenseInfoVOIOV.licenseNumber,
            driverLicenseImageKeyList: this.driverLicenseImageKeyList || null,
            driverLicenseRemark: this.form.drivingLicenseInfoVOIOV.driverLicenseRemark,
            
          },
          transportLicenseInfoVOIOV: {
            transportLicenseId: this.form.transportLicenseInfoVOIOV.transportLicenseId,
            transportNumber: this.form.transportLicenseInfoVOIOV
              .transportNumber,
            businessScope: this.form.transportLicenseInfoVOIOV.businessScope,
            startTime:
              this.form.transportLicenseInfoVOIOV.transportValidity == null
                ? null
                : this.form.transportLicenseInfoVOIOV.transportValidity[0],
            endTime:
              this.form.transportLicenseInfoVOIOV.transportValidity == null
                ? null
                : this.form.transportLicenseInfoVOIOV.transportValidity[1],
            transportLicenseImageKeyList:
              this.transportLicenseImageKeyList || null,
            transportLicenseRemark: this.form.transportLicenseInfoVOIOV
              .transportLicenseRemark,
          },
        },
      });
      this.$notify({
        title: "成功",
        message: "新增成功",
        type: "success",
      });
      this.$router.push("/vehicle-management/car-management");
    },
    // 校验判断
    submitForm() {
      const deviceId = this.form.steelCarSatelliteInfoVO.deviceId;
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          if (this.stateShape === "启用") {
            if (
              deviceId !== null &&
              deviceId !== "" &&
              deviceId !== undefined
            ) {
              this.submitInternal();
            } else {
              this.$message({
                message: "请输入设备号",
                type: "warning",
              });
              this.submitLoading = false;
              return;
            }
          } else {
            this.submitInternal();
          }
        } catch (e) {}
        this.submitLoading = false;
      });
    },

    oilMonitoringClick(val) {
      if (val === "启用") {
        this.stateShape = val;
      } else {
        this.stateShape = "未启用";
        this.tankShapeItem = null;
        this.form.tankShapeData.shape = null;
        this.form.tankShapeData.long = null; //长
        this.form.tankShapeData.wide = null; //宽
        this.form.tankShapeData.height = null; //高
        this.form.tankShapeData.diameter = null; //直径
        this.form.tankShapeData.longDiameter = null; //长直径
        this.form.tankShapeData.shortDiameter = null; //短直径
        this.form.tankShapeData.neckHeight = null; //颈高
        this.form.tankShapeData.rodLength = null; //油杆长
        this.form.tankShapeData.thicknessTank = null; //油箱厚度
        this.form.tankShapeData.maxCalibration = null; //最大标定值
        this.form.tankShapeData.correctionParameter = null; //容积修正参数
      }
    },
    tankShapeClick(val) {
      if (val === null) {
        this.tankShapeItem = null;
      } else if (val === "长方形") {
        this.tankShapeItem = val;
        this.form.tankShapeData.long = null; //长
        this.form.tankShapeData.wide = null; //宽
        this.form.tankShapeData.height = null; //高
        this.form.tankShapeData.diameter = null; //直径
        this.form.tankShapeData.longDiameter = null; //长直径
        this.form.tankShapeData.shortDiameter = null; //短直径
        this.form.tankShapeData.neckHeight = null; //颈高
        this.form.tankShapeData.rodLength = null; //油杆长
        this.form.tankShapeData.thicknessTank = "0.3"; //油箱厚度
        this.form.tankShapeData.maxCalibration = null; //最大标定值
        this.form.tankShapeData.correctionParameter = null; //容积修正参数
      } else if (val === "圆形") {
        this.tankShapeItem = val;
        this.form.tankShapeData.long = null; //长
        this.form.tankShapeData.wide = null; //宽
        this.form.tankShapeData.height = null; //高
        this.form.tankShapeData.diameter = null; //直径
        this.form.tankShapeData.longDiameter = null; //长直径
        this.form.tankShapeData.shortDiameter = null; //短直径
        this.form.tankShapeData.neckHeight = null; //颈高
        this.form.tankShapeData.rodLength = null; //油杆长
        this.form.tankShapeData.thicknessTank = "0.3"; //油箱厚度
        this.form.tankShapeData.maxCalibration = null; //最大标定值
        this.form.tankShapeData.correctionParameter = null; //容积修正参数
      } else if (val === "D型") {
        this.tankShapeItem = val;
        this.form.tankShapeData.long = null; //长
        this.form.tankShapeData.wide = null; //宽
        this.form.tankShapeData.height = null; //高
        this.form.tankShapeData.diameter = null; //直径
        this.form.tankShapeData.longDiameter = null; //长直径
        this.form.tankShapeData.shortDiameter = null; //短直径
        this.form.tankShapeData.neckHeight = null; //颈高
        this.form.tankShapeData.rodLength = null; //油杆长
        this.form.tankShapeData.thicknessTank = "0.3"; //油箱厚度
        this.form.tankShapeData.maxCalibration = null; //最大标定值
        this.form.tankShapeData.correctionParameter = null; //容积修正参数
      } else if (val === "椭圆形") {
        this.tankShapeItem = val;
        this.form.tankShapeData.long = null; //长
        this.form.tankShapeData.wide = null; //宽
        this.form.tankShapeData.height = null; //高
        this.form.tankShapeData.diameter = null; //直径
        this.form.tankShapeData.longDiameter = null; //长直径
        this.form.tankShapeData.shortDiameter = null; //短直径
        this.form.tankShapeData.neckHeight = null; //颈高
        this.form.tankShapeData.rodLength = null; //油杆长
        this.form.tankShapeData.thicknessTank = "0.5"; //油箱厚度
        this.form.tankShapeData.maxCalibration = null; //最大标定值
        this.form.tankShapeData.correctionParameter = null; //容积修正参数
      }
    },
  },

  async mounted() {
    // 下拉数据
    let driverList = await this.$api.listCarTeamInfoByQueryCondition({
      queryContent: null,
      currentPage: null,
      pageSize: null,
    });
    for (let i = 0; i < driverList.data.length; i++) {
      this.driverList.push({
        teamName: driverList.data[i].teamName,
        groupId: driverList.data[i].groupId,
      });
    }

    // 表单回显数据
    let formData = await this.$api.getCarSatelliteInfoByPlateNumber({
      plateNumber: this.$route.query.plateNumber,
    });
    this.stateShape = formData.steelCarSatelliteInfoVO.hasFuel;
    if (formData.steelCarSatelliteInfoVO.hasFuel === "启用") {
      this.tankShapeItem = formData.carFuelInfoVO.fuelTankType
      this.form.tankShapeData = {
        id: formData.carFuelInfoVO.id,
        shape: formData.carFuelInfoVO.fuelTankType, //油箱形状
        long: formData.carFuelInfoVO.length, //长
        wide: formData.carFuelInfoVO.width, //宽
        height: formData.carFuelInfoVO.height, //高
        diameter: formData.carFuelInfoVO.diameter, //直径
        longDiameter: formData.carFuelInfoVO.longDiameter, //长直径
        shortDiameter: formData.carFuelInfoVO.shortDiameter, //短直径
        neckHeight: formData.carFuelInfoVO.neckHeight, //颈高
        rodLength: formData.carFuelInfoVO.fuelRodLength, //油杆长
        thicknessTank: formData.carFuelInfoVO.thickness, //油箱厚度
        maxCalibration: formData.carFuelInfoVO.maxCalibration, //最大标定值
        correctionParameter: formData.carFuelInfoVO.volumeRevisionFactor, //容积修正参数
      };
    }else{
      this.form.tankShapeData.id = formData.carFuelInfoVO.id
    }

    this.form.steelCarSatelliteInfoVO = formData.steelCarSatelliteInfoVO
    this.form.insuranceInfoVOIOV = formData.insuranceInfoVOIOV
    this.form.drivingLicenseInfoVOIOV = formData.drivingLicenseInfoVOIOV
    this.form.transportLicenseInfoVOIOV = formData.transportLicenseInfoVOIOV

    // 日期转换
    if (formData.steelCarSatelliteInfoVO.annualInspectionStartTime) {
      this.$set(this.form.steelCarSatelliteInfoVO, "validity", [
        formData.steelCarSatelliteInfoVO.annualInspectionStartTime,
        formData.steelCarSatelliteInfoVO.annualInspectionEndTime,
      ]);
    }
    // 日期转换
    if (formData.insuranceInfoVOIOV.startTime) {
      this.$set(this.form.insuranceInfoVOIOV, "insuranceValidity", [
        formData.insuranceInfoVOIOV.startTime,
        formData.insuranceInfoVOIOV.endTime,
      ]);
    }
    // 日期转换
    if (formData.drivingLicenseInfoVOIOV.startTime) {
      this.$set(this.form.drivingLicenseInfoVOIOV, "drivingValidity", [
        formData.drivingLicenseInfoVOIOV.startTime,
        formData.drivingLicenseInfoVOIOV.endTime,
      ]);
    }
    // 日期转换
    if (formData.transportLicenseInfoVOIOV.startTime) {
      this.$set(this.form.transportLicenseInfoVOIOV, "transportValidity", [
        formData.transportLicenseInfoVOIOV.startTime,
        formData.transportLicenseInfoVOIOV.endTime,
      ]);
    }
    // 图片
    if (formData.steelCarSatelliteInfoVO.carImageUrl) {
      this.carImageKey = [formData.steelCarSatelliteInfoVO.carImageUrl];
    }
    this.driverLicenseImageKeyList =
      formData.drivingLicenseInfoVOIOV.driverLicenseImageKeyList || [];
    this.transportLicenseImageKeyList =
      formData.transportLicenseInfoVOIOV.transportLicenseImageKeyList || [];
    // 下拉数据
    this.deviceIDList = await this.$api.listUninstallDeviceId({});
    if (this.form.steelCarSatelliteInfoVO.deviceId) {
      this.deviceIDList = this.deviceIDList.concat({
        deviceId: this.form.steelCarSatelliteInfoVO.deviceId,
        deviceName: this.form.steelCarSatelliteInfoVO.deviceName,
      });
    }

    // 挂靠单位
    this.companyList = await this.$api.listCarUnit({});
  },
};
</script>

<style lang="sass" scoped>
.form-label-base
  width: 100%
  padding-top: 20px
  border-top: 1px solid #eee
.none-title
  border: none
  padding-top: 0

/deep/ .el-form-item
  display: flex
  width: 100%

/deep/ .el-range-editor.el-input__inner,
/deep/ .el-select
  width: 100%
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1
/deep/ .el-form-item--mini .el-form-item__label
  line-height: 30px
</style>

